<template>
  <div>
    <h3>Rate limits <span class="text-xl">🕒</span></h3>

    <p class="mb-4">
      Snakes love sharing their shiny tokens, but even the most generous need
      their beauty sleep! 🌙
    </p>

    <p class="mb-4">
      To ensure fairness, this faucet has a
      <span class="text-yellow-600">12 hour</span> cooldown. No exceptions, not
      even for the fanciest snakes. 🕰️
    </p>

    <p>
      Your next claim will come around before you know it. In the meantime, you
      can spread the word, check your wallet, or dream about going to the moon.
      🌕
    </p>
  </div>
</template>
