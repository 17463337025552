<template>
  <div class="flex flex-col items-center min-h-screen">
    <header>
      <h1>Snake wif Hat Faucet</h1>

      <NavComponent />
    </header>

    <main class="relative flex flex-grow items-center max-w-screen-lg p-4">
      <MainLayout :adblock="adblock" />
    </main>

    <footer>
      <p>Thanks for being part of the Snake wif Hat revolution.</p>
      <p>
        This faucet isn’t officially slithering with the $SSSSS devs. No
        guarantees, no liability. Just a fun way to spread some snake love!
        🐍🎩❤️
      </p>
    </footer>
  </div>
</template>

<script>
import MainLayout from "./layouts/MainLayout.vue";

import NavComponent from "./components/NavComponent.vue";

export default {
  components: {
    MainLayout,

    NavComponent,
  },
  data() {
    return {
      adblock: false,
    };
  },
  beforeMount() {
    const testUrl = "//acceptable.a-ads.com/";

    fetch(new Request(testUrl), {
      mode: "no-cors",
    })
      .then(() => {
        this.adblock = false;
      })
      .catch(() => {
        this.adblock = true;
      });
  },
};
</script>
