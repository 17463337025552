<template>
  <div>
    <div v-if="!adblock" class="ad">
      <iframe
        :data-aa="id"
        :src="`//acceptable.a-ads.com/${id}?size=Adaptive&title_color=ca8a04&title_hover_color=eab308&link_color=ca8a04&link_hover_color=eab308`"
        class="ad"
      />
    </div>
    <div v-if="adblock" class="card flex items-center justify-center">
      <div class="adblock">
        <p class="text-3xl">🚫</p>
        <p>Please disable adblock!</p>
        <p>Ads keep the faucet running!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "adblock"],
};
</script>
