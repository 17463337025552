<template>
  <div class="notification">
    <PillComponent :level="level" :message="message" class="text-nowrap" />
  </div>
</template>

<script>
import PillComponent from "./PillComponent.vue";

export default {
  components: {
    PillComponent,
  },
  data() {
    return {
      interval: null,

      level: null,
      message: null,
    };
  },
  methods: {
    show(level, message) {
      clearInterval(this.timeout);

      this.level = level;
      this.message = message;

      this.timeout = setTimeout(() => {
        this.message = null;
      }, 3000);
    },
  },
};
</script>
