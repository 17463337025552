<template>
  <div>
    <nav>
      <NavItemComponent href="https://snakewifhat.pro" title="Website" />
      |
      <NavItemComponent href="https://x.com/Snake_wif_Hat" title="Twitter" /> |
      <NavItemComponent href="http://t.me/snake_wif_hat" title="Telegram" /> |
      <NavItemComponent
        href="https://reddit.com/r/SnakewifHat"
        title="Reddit"
      />
    </nav>
    <nav>
      <NavItemComponent
        href="https://raydium.io/swap/?inputMint=sol&outputMint=5EdKas4QsHaa71QS1X98wiCTgJCCVMjw5GhY5QMKpump"
        title="Raydium"
      />
      |
      <NavItemComponent
        href="https://dexscreener.com/solana/7uzb55p6njkh3zouxhovywo3zendi6qddsfkablpzvsp"
        title="DEX Screener"
      />
    </nav>
  </div>
</template>

<script>
import NavItemComponent from "./NavItemComponent.vue";

export default {
  components: {
    NavItemComponent,
  },
};
</script>
