<template>
  <div>
    <p>
      Welcome to the <span class="text-yellow-600">Snake wif Hat</span> Faucet!
      This is your one-stop shop for slithering your way into the memecoin
      world! Together, we’re making snakes cute again! 🐍🎩❤️
    </p>

    <h3>What is this faucet?</h3>

    <p>
      This faucet is your ticket to free
      <span class="text-yellow-600">$SSSSS</span>! Whether you’re new to crypto
      or just want to snag some snake swag, this faucet is here to help you jump
      (or slither) in.
    </p>

    <p>
      Every time you claim, you'll receive the equivalent of
      <span class="text-yellow-600">$0.001 USD</span>. It might not seem like
      much, but we're aiming for a
      <span class="text-yellow-600">x1000 increase</span> (HODL! 💎), so maybe
      someday each claim will be worth 1 whole dollar!
    </p>

    <h3>How to fill the faucet? <span class="text-xl">💸</span></h3>

    <p>
      Think the faucet needs more juice? Just send some $SSSSS or $SOL to the
      faucet wallet. $SSSSS is for the tokens, $SOL to cover transaction fees.
      Any kind soul can fill it up and keep everything running smoothly.
    </p>

    <div class="address" @click="copyToClipboard('faucet', faucetWallet)">
      Faucet wallet address:
      <code>{{ faucetWallet }}</code>
    </div>

    <h3>Want to help the dev? <span class="text-xl">💻</span></h3>

    <p>
      Love the faucet? Help me keep the lights on (and the snakes warm).
      Donations are always welcome!
    </p>

    <div class="address" @click="copyToClipboard('dev', devWallet)">
      <span>Dev wallet address: </span>
      <code>{{ devWallet }}</code>
    </div>

    <p>
      Plus, auto-donations are built into the system. Whenever someone claims
      tokens in the faucet, a small percentage slides over to the dev to
      maintain the infrastructure. Talk about a smooth operator! ⚡🎩
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faucetWallet: "HuemLGGzU5Sf8vxSQJek15w84MePRpnT3qhEC5WUf53J",
      devWallet: "B896grQ58xAGbaED1pYuJ7ZE4ugkwL6yE9TQieM2Sreo",
    };
  },
  methods: {
    async copyToClipboard(name, address) {
      try {
        if (navigator.clipboard && window.isSecureContext) {
          navigator.clipboard.writeText(address);
        } else {
          const textarea = document.createElement("textarea");
          textarea.display = "none";
          textarea.value = address;

          document.body.prepend(textarea);
          textarea.select();

          try {
            document.execCommand("copy");
          } finally {
            textarea.remove();
          }
        }
        this.$emit("show-notification", "info", `${name} address copied!`);
      } catch (err) {
        this.$emit("show-notification", "error", "Failed to copy address");
      }
    },
  },
};
</script>
