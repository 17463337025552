<template>
  <div>
    <div v-if="!adblock">
      <h2>Claim <span class="text-yellow-600">FREE</span> tokens</h2>

      <form class="mb-4" @submit.prevent="submitClaim">
        <div class="mb-4">
          <label for="wallet">Your wallet address:</label>
          <input
            type="text"
            required
            placeholder="Enter your wallet address"
            v-model="walletAddress"
          />
        </div>
        <div class="flex justify-center mb-4">
          <vue-hcaptcha
            ref="captcha"
            size="normal"
            theme="light"
            sitekey="a876c92c-935e-41f8-957e-12340f5e1bd8"
            @verify="validateCaptcha"
            @expired="invalidateCaptcha"
            @challenge-expired="invalidateCaptcha"
            @error="invalidateCaptcha"
          />
        </div>
        <div>
          <button
            type="submit"
            :disabled="disabled"
            :class="{
              'bg-gray-300': disabled,
              'bg-yellow-600': !disabled,
              'hover:bg-yellow-500': !disabled,
              'hover:scale-110': !disabled,
            }"
          >
            Claim Tokens
          </button>
        </div>
      </form>

      <p>
        Claims take a few minutes to slither into your wallet. Patience is a
        virtue, even for serpents.
      </p>

      <PillComponent ref="pill" :level="level" :message="message" />
    </div>
    <div v-if="adblock">
      <h2>Claim <span class="text-yellow-600">FREE</span> tokens</h2>

      <div class="adblock">
        <p class="text-3xl">🚫</p>
        <p>Hey, snake friend! 🐍</p>
        <p>
          Here you claim free <span class="text-yellow-600">$SSSSS</span>, but
          the section has slithered away.
        </p>
        <p>
          Please disable adblock so we can share
          <span class="text-yellow-600">$SSSSS</span> with you.
        </p>
        <p>Thanks for understanding!</p>
      </div>
    </div>
  </div>
</template>

<script>
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";

import PillComponent from "../components/PillComponent.vue";

export default {
  components: {
    VueHcaptcha,

    PillComponent,
  },
  props: ["adblock"],
  data() {
    return {
      cooldown: false,

      error: true,
      message: null,

      walletAddress: null,
      captchaToken: null,
    };
  },
  computed: {
    disabled() {
      return this.cooldown || !this.walletAddress || !this.captchaToken;
    },

    level() {
      return this.error ? "error" : "info";
    },
  },
  methods: {
    validateCaptcha(token) {
      this.captchaToken = token;
    },

    invalidateCaptcha() {
      this.captchaToken = null;
    },

    async submitClaim() {
      if (!this.walletAddress) {
        this.error = true;
        this.message = "Please enter your wallet address";
        return;
      }

      if (!this.captchaToken) {
        this.error = true;
        this.message = "Please solve the CAPTCHA";
        return;
      }

      this.cooldown = true;

      try {
        const response = await fetch("http://localhost:5000/claim", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            walletAddress: this.walletAddress,
            captchaToken: this.captchaToken,
          }),
        });

        const data = await response.json();

        this.$refs.captcha.reset();
        this.captchaToken = null;

        if (data.success) {
          this.error = false;
          this.message = "Your claim was successful!";
        } else {
          this.error = true;
          this.message = data.error;
        }
      } catch (err) {
        this.error = true;
        this.message = "Error claiming token";
      } finally {
        setTimeout(() => {
          this.cooldown = false;

          this.$refs.captcha.reset();
          this.captchaToken = null;
        }, 3000);
      }
    },
  },
};
</script>
