<template>
  <div>
    <h3>Faucet stats</h3>

    <div class="grid gap-4 grid-static-2 lg:grid-static-1">
      <StatsItemComponent
        title="$SSSSS available"
        :error="error"
        :value="stats.tokenBalance"
        class="order-1 lg:order-1"
      />
      <StatsItemComponent
        title="$SOL available"
        :error="error"
        :value="stats.solBalance"
        class="order-3 lg:order-2"
      />

      <StatsItemComponent
        title="Total $SSSSS claimed"
        :error="error"
        :value="stats.tokenSent"
        class="order-2 lg:order-3"
      />
      <StatsItemComponent
        title="Total transactions"
        :error="error"
        :value="stats.tokenTransactions"
        class="order-4 lg:order-4"
      />

      <PillComponent
        ref="pill"
        level="error"
        :message="message"
        class="col-span-2 lg:col-span-1 order-5"
      />
    </div>
  </div>
</template>

<script>
import PillComponent from "../components/PillComponent.vue";
import StatsItemComponent from "../components/StatsItemComponent.vue";

let interval = null;

export default {
  components: {
    PillComponent,
    StatsItemComponent,
  },
  data() {
    return {
      error: false,
      message: null,

      stats: {
        solBalance: 0,
        tokenBalance: 0,
        tokenSent: 0,
        tokenTransactions: 0,
      },
    };
  },
  methods: {
    async fetchStats() {
      try {
        const response = await fetch("http://localhost:5000/stats");
        const data = await response.json();

        if (data.success) {
          this.error = false;
          this.message = null;

          this.stats.solBalance = +data.data.solBalance.toFixed(8);
          this.stats.tokenBalance = +data.data.tokenBalance.toFixed(8);
          this.stats.tokenSent = +data.data.tokenSent.toFixed(8);
          this.stats.tokenTransactions = data.data.tokenTransactions;
        } else {
          this.error = true;
          this.message = data.error;
        }
      } catch (err) {
        this.error = true;
        this.message = "Error getting stats";
      }
    },
  },
  mounted() {
    this.fetchStats();
    interval = setInterval(this.fetchStats, 60 * 1000);
  },
  unmounted() {
    clearInterval(interval);
  },
};
</script>
