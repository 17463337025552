<template>
  <div>
    <span>{{ title }}</span>
    <p class="text-xl text-yellow-600">{{ error ? "NaN" : value }}</p>
  </div>
</template>

<script>
export default {
  props: ["title", "value", "error"],
};
</script>
