<template>
  <div>
    <NotificationComponent ref="notification" />

    <div class="grid gap-4 grid-static-1 lg:grid-static-12 max-w-screen-lg">
      <AdComponent
        class="order-1 lg:order-1 lg:col-span-12"
        id="2368885"
        :adblock="adblock"
      />

      <StatsSection class="card order-4 lg:order-2 lg:col-span-3" />
      <ClaimSection
        class="card order-4 lg:order-2 lg:col-span-5"
        :adblock="adblock"
      />
      <LimitsSection class="card order-4 lg:order-2 lg:col-span-4" />

      <AdComponent
        class="order-3 lg:order-3 lg:col-span-12"
        id="2368886"
        :adblock="adblock"
      />

      <InfoSection
        class="card order-2 lg:order-4 lg:col-span-9"
        @show-notification="showNotification"
      />

      <AdComponent
        class="order-5 lg:order-5 lg:col-span-3"
        id="2368887"
        :adblock="adblock"
      />
    </div>
  </div>
</template>

<script>
import ClaimSection from "../sections/ClaimSection.vue";
import InfoSection from "../sections/InfoSection.vue";
import LimitsSection from "../sections/LimitsSection.vue";
import StatsSection from "../sections/StatsSection.vue";

import AdComponent from "../components/AdComponent.vue";
import NotificationComponent from "../components/NotificationComponent.vue";

export default {
  components: {
    ClaimSection,
    InfoSection,
    LimitsSection,
    StatsSection,

    AdComponent,
    NotificationComponent,
  },
  props: ["adblock"],
  methods: {
    showNotification(level, message) {
      this.$refs.notification.show(level, message);
    },
  },
};
</script>
