<template>
  <div v-if="message" :class="color" class="pill">
    {{ message }}
  </div>
</template>

<script>
export default {
  props: ["level", "message"],
  computed: {
    color() {
      switch (this.level) {
        case "info":
          return "bg-green-600";

        case "error":
          return "bg-red-600";

        default:
          return "bg-gray-200";
      }
    },
  },
};
</script>
